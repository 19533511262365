import React, { useEffect, useState } from 'react'
import { TermsAndConditions } from '../../terms-and-conditions/TermsAndConditions'
import { acceptedTermsOfService } from '../../terms-and-conditions/termsOfServiceHelper'
import { Modal } from '../../shared/Modal'

export const ViewTermsAndConditions = () => {
  const [acceptedTerms, setAcceptedTerms] = useState<Date | null>(null)
  const [showTermsAndConditions, setShowTermsAndConditions] = useState(false)

  useEffect(() => {
    acceptedTermsOfService().then(isoDate => setAcceptedTerms(isoDate))
  }, [])

  const closeTerms = () => setShowTermsAndConditions(false)

  return (
    <>
      {acceptedTerms && (
        <button className="link" onClick={() => setShowTermsAndConditions(!showTermsAndConditions)}>
          View terms (accepted {acceptedTerms.toLocaleDateString()})
        </button>
      )}

      <Modal isOpen={showTermsAndConditions}>
        <div className="dialog max-h-[60vh] overflow-y-scroll !rounded-b-none">
          <div className="p-6">
            <TermsAndConditions />
          </div>
        </div>
        <div className="dialog !rounded-t-none p-6 flex justify-end">
          <button type="button" className="btn" onClick={closeTerms}>
            Close
          </button>
        </div>
      </Modal>
    </>
  )
}
