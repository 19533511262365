import React from 'react'
import { Header } from './Header'
import { HomeNavBar } from '../../navigation/HomeNavBar'

export const HomePage = () => {
  return (
    <div className="flex flex-col w-full">
      <HomeNavBar />
      <Header />

      <div className="w-full grid grid-cols-12 gap-6 items-start max-w-screen-xl px-6 mx-auto -mt-36">
        <div className="col-span-12 mx-auto lg:col-span-4 p-8 prose card">
          <h2>Welcome</h2>
          <p>
            Welcome to TOMRA Collection Developer. You are now able to complement and extend the use of the TOMRA
            Reverse Vending Machine&apos;s by exploring our API offering.
          </p>
        </div>

        <div className="col-span-12 mx-auto lg:col-span-4 p-8 prose card">
          <h2>Get started</h2>
          <p>
            <a href="https://www.tomra.com/en/contact-us" target="_blank" rel="noreferrer">
              Contact your TOMRA Collection sales office
            </a>
            <br />
            to gain access to the Developer Portal. Get your credentials and get a test account to start testing.
            We`&apos;ll guide you through the steps to become an API user.
          </p>
        </div>

        <div className="col-span-12 mx-auto lg:col-span-4 p-8 prose card">
          <h2>Why</h2>
          <p>
            TOMRA Collection APIs offers a single safe source to access your fleets Reverse Vending Machine data. We
            enable you to securely access data to build your application`&apos;s to take out the full potential of your
            fleet to optimize operations and digitalize the customer experience.
          </p>
        </div>
      </div>
    </div>
  )
}
