import React from 'react'
import { CopyTextField } from '../common/CopyTextField'
import { OAuthClientSecret } from './OAuthClientSecret'
import { Link, useLoaderData, useParams } from 'react-router-dom'
import { OAuthClient } from '../../../../types'

export const OAuthClientDetail = () => {
  const { environment } = useParams()
  const client = useLoaderData() as OAuthClient

  return (
    <div className="flex flex-col space-y-6 items-start">
      <h2 className="text-lg">{client.name}</h2>

      <div className="w-full">
        <CopyTextField label="Client ID" value={client.id} />
      </div>

      <OAuthClientSecret client={client} />

      <Link
        to={`/account/${environment}/oauth-clients/${client.id}/delete`}
        aria-label={`Delete "${client.name}"`}
        className="btn border-2 border-warm-red text-warm-red"
      >
        Delete &quot;{client.name}&quot;
      </Link>
    </div>
  )
}
