import React, { useEffect, useState } from 'react'
import { Form, useNavigation, useParams, useRouteLoaderData, useSearchParams } from 'react-router-dom'
import { Webhook, WebhookEventHistoryType } from '../../../../../types'
import { WebhookHistoryEvent } from './WebhookHistoryEvent'
import { PaginatedContent } from '../../../../shared/PaginatedContent'
import { WebhookEventSkeleton } from './WebhookEventSkeleton'
import { ampli } from '../../../../../ampli'
import { getWebhookHistory, retryAllWebhookDeliveries } from '../../../../../services/account-service'
import { logError } from '@tomra/datadog-browser-logging'
import { RetryIcon } from '../../../../icons/RetryIcon'

export const WebhookHistory = () => {
  const { environment, webhookId } = useParams() as { environment: string; webhookId: string }
  const [searchParams, setSearchParams] = useSearchParams()
  const [requestStatus, setRequestStatus] = useState('idle')
  const [events, setEvents] = useState<WebhookEventHistoryType[]>([])
  const [nextPage, setNextPage] = useState<string>('')

  const { location } = useNavigation()
  const { active } = useRouteLoaderData('webhook') as Webhook
  const filter = searchParams.get('status') as WebhookEventHistoryType['status']
  const currentPage = searchParams.get('next') as string

  const failedEvents = events?.filter(event => event.status === 'FAILED') || []
  const hasEvents = events?.length > 0

  const isLastPage = Boolean(currentPage && !hasEvents)

  async function setFilter(status: string) {
    ampli.filterWebhookEvents({ status })
    setSearchParams(status ? { status } : {})
  }

  async function fetchEvents() {
    try {
      setRequestStatus('pending')
      const { events, next } = await getWebhookHistory(webhookId, environment, filter, currentPage)
      setEvents(events)
      setNextPage(next)
      setRequestStatus('idle')
    } catch (error: unknown) {
      logError(new Error('Unable to fetch webhook events'), error as Error)
      setRequestStatus('failure')
    }
  }

  async function retryAll() {
    try {
      await retryAllWebhookDeliveries(webhookId, environment)
      await fetchEvents()
    } catch (error: unknown) {
      logError(new Error('Unable to retry all webhook deliveries'), error as Error)
      setRequestStatus('failure')
    }
  }

  useEffect(() => {
    fetchEvents()
  }, [searchParams])

  return (
    <div className="card p-6">
      {requestStatus === 'failure' ? (
        <div>
          <div className="text-warm-red-dark">Something went wrong</div>
          <button className="btn mt-4" onClick={fetchEvents}>
            Reload
          </button>
        </div>
      ) : !hasEvents && !active ? (
        <Form
          method="post"
          action={`/account/${environment}/webhooks/${webhookId}`}
          className="mt-xl flex justify-center"
        >
          <button type="submit" className="btn primary">
            Activate webhook to receive events
          </button>
        </Form>
      ) : !hasEvents && !filter ? (
        <div className="text-center">No events, yet</div>
      ) : (
        <PaginatedContent showPaginationLinks={hasEvents || isLastPage} next={nextPage}>
          <div className="flex flex-col">
            <div className="flex items-center justify-between mb-6">
              <div className="flex items-center space-x-4">
                <h2 className="text-2xl capitalize">{filter} Events</h2>

                {failedEvents.length > 0 && (
                  <button className="chip !bg-bg-color !px-2" onClick={retryAll} disabled={requestStatus === 'pending'}>
                    {requestStatus === 'pending' ? (
                      <span className="loading-infinity" />
                    ) : (
                      <>
                        <RetryIcon />
                        <span className="ml-1 !bg-bg-color !w-auto">Retry all</span>
                      </>
                    )}
                  </button>
                )}
              </div>

              <div className="flex space-x-2">
                <button className={`filter ${!filter && 'selected'}`} onClick={() => setFilter('')}>
                  All
                </button>
                <button
                  className={`filter ${filter?.toUpperCase() === 'DELIVERED' && 'selected'}`}
                  onClick={() => setFilter('delivered')}
                >
                  Delivered
                </button>
                <button
                  className={`filter ${filter?.toUpperCase() === 'RETRYING' && 'selected'}`}
                  onClick={() => setFilter('retrying')}
                >
                  Retrying
                </button>
                <button
                  className={`filter ${filter?.toUpperCase() === 'FAILED' && 'selected'}`}
                  onClick={() => setFilter('failed')}
                >
                  Failed
                </button>
              </div>
            </div>

            {location?.search || requestStatus === 'pending' ? (
              <ul className="flex flex-col space-y-6">
                <li>
                  <WebhookEventSkeleton />
                </li>
                <li>
                  <WebhookEventSkeleton />
                </li>
                <li>
                  <WebhookEventSkeleton />
                </li>
              </ul>
            ) : isLastPage ? (
              <div className="text-center text-storm mt-6">No more events with status {filter}</div>
            ) : !hasEvents ? (
              <div className="text-center text-storm mt-6">No events with status {filter}</div>
            ) : (
              <ul>
                {events.map(event => (
                  <WebhookHistoryEvent key={`ev-${event.eventId}`} event={event} />
                ))}
              </ul>
            )}
          </div>
        </PaginatedContent>
      )}
    </div>
  )
}
